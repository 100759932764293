export default {
    paymentTypes: {
        finance: 1000,
        creditCard: 2000,
    },
    states: {
        pending: {
            id: 1000,
            key: 'pending',
            displayName: 'Pending',
        },
        pendingNonPartner: {
            id: 9000,
            key: 'pendingNonPartner',
            displayName: 'Pending (Non Partner)',
        },
        confirmed: {
            id: 2000,
            key: 'confirmed',
            displayName: 'Confirmed',
        },
        approved: {
            id: 3000,
            key: 'approved',
            displayName: 'Credit Approved',
        },
        procured: {
            id: 7000,
            key: 'procured',
            displayName: 'Products Procured',
        },
        scheduled: {
            id: 8000,
            key: 'scheduled',
            displayName: 'Delivery Scheduled',
        },
        transit: {
            id: 4000,
            key: 'transit',
            displayName: 'Out for Delivery',
        },
        delivered: {
            id: 5000,
            key: 'delivered',
            displayName: 'Delivered',
        },
        cancelled: {
            id: 6000,
            key: 'cancelled',
            displayName: 'Cancelled',
        },
    },
};
