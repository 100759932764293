export default {
    interestRates: {
        tt: 0.03,
        bb: 0.05,
        gy: 0.05,
    },
    infinityIds: {
        tt: 473,
        bb: 403,
        gy: 404,
    },
};
