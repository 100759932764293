<script>
    import { onMount } from 'svelte';

    import user from 'stores/user';
    import shop from 'stores/shop';
    import shoppingCart from 'stores/shoppingCart';
    import { getRetailerLogoUrl } from 'services/shop';

    import { formatCurrency } from 'services/util';

    import Button from 'components/Button';
    import Lottie from 'components/Lottie';

    export let product;

    let canSeeMoreInfo = false;
    let lastProductAddedId = null;

    function handleClick(product) {
        shoppingCart.add(product);

        lastProductAddedId = product.id;
        setTimeout(() => {
            lastProductAddedId = null;
        }, 1500);
    }

    function handleMoreInfoShow() {
        canSeeMoreInfo = true;
    }

    function handleMoreInfoHide() {
        canSeeMoreInfo = false;
    }
</script>

<style lang="scss">
    @import 'sass/base';

    .product {
        height: 300px;
        background: $color-gray-light;
        transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

        &:hover {
            animation: pulse;
            animation-duration: 0.25s;
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05), 0 6px 6px rgba(0, 0, 0, 0.1);
        }

        &--out-of-stock {
            display: none; // This is hacky but it'll work for now. Eventually remove out of stock products from the query.
            opacity: 1;
        }

        @include for-tablet-portrait-up {
            height: 420px;
        }
    }

    .thumbnail {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        height: 40%;
        background-color: $color-white;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        cursor: pointer;

        @include for-tablet-portrait-up {
            height: 50%;
        }

        &--out-of-stock {
            cursor: not-allowed;

            .thumbnail__img {
                filter: grayscale(100%);
                opacity: 0.5;
            }

            .thumbnail__overlay {
                display: none !important;
            }
        }

        &__img {
            width: auto;
            height: 100%;
        }

        &:hover {
            .thumbnail__overlay {
                display: flex;
                background: rgba($color-primary, 0.8);
            }
        }

        &__overlay {
            display: none;
            justify-content: center;
            align-items: center;
            position: absolute;
            z-index: 100;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: transparent;
            animation-duration: 0.25s;

            &--out-of-stock {
                display: none !important;
            }
        }
    }

    .checkmark {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        z-index: 200;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $color-white;
        animation-duration: 0.25s;

        &__icon {
            width: 100px;
            height: 100px;

            @include for-tablet-portrait-up {
                width: 200px;
                height: 200px;
            }
        }
    }

    .details {
        display: flex;
        position: relative;
        overflow: hidden;
        flex-flow: column wrap;
        justify-content: space-evenly;
        align-items: center;
        height: 45%;
        padding: em(10) em(20);

        @include for-tablet-portrait-up {
            height: 40%;
        }

        &__group {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            &--summary {
                height: 75%;
            }

            &--price {
                justify-content: flex-start;
                height: 25%;
            }
        }
    }

    .details-summary {
        width: 70%;

        &__title {
            margin-bottom: em(5);
            font-size: em(16);
            font-weight: $fw-bold;
            line-height: 1;
        }

        &__desc {
            font-size: em(14);
            color: $color-gray-text;
        }

        &__price {
            font-size: em(16);

            @include for-tablet-portrait-up {
                font-size: em(20);
                font-weight: $fw-regular;
            }

            &--strike {
                margin-right: em(10);
                text-decoration: line-through;
                font-size: em(14);
                opacity: 0.5;
            }

            &--sale {
                color: $color-green;
            }
        }
    }

    .info {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: em(25);
        background: $color-gray-light;
        animation-duration: 250ms;

        &__icon {
            width: 40px;
            height: 40px;
        }
    }

    .info-details {
        width: 80%;

        &__title {
            margin-bottom: em(5);
            font-size: em(14);
            font-weight: $fw-bold;
        }

        &__price {
            margin-bottom: em(10);
            font-size: em(14);
            font-weight: $fw-light;
        }

        &__desc {
            font-size: em(14);
            color: $color-gray-text;
        }
    }

    .actions {
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        align-items: center;
        background: $color-white;
        height: 15%;
        padding: em(8);

        @include for-tablet-portrait-up {
            height: 10%;
            flex-flow: row nowrap;
        }

        &__btn-add {
            @include btn-primary;
            display: inline-block;
            height: 100%;
            width: 100%;
            margin: 0 em(5);
            padding: 0;
            font-size: em(10);

            @include for-tablet-portrait-up {
                width: 40%;
                height: 100%;
            }

            &--out-of-stock {
                cursor: not-allowed;
                background: lighten($color-primary, 10%);
            }
        }

        &__btn-info {
            @include btn-secondary;
            cursor: default;
            display: none;

            @include for-tablet-portrait-up {
                display: inline-block;
                height: 50%;
                width: 100%;
                margin: 0 em(5);
                padding: 0;
                font-size: em(10);
                width: 40%;
                height: 100%;
            }
        }
    }
</style>

<div class="product" class:product--out-of-stock={!product.inStock}>
    <div class="thumbnail" class:thumbnail--out-of-stock={!product.inStock}>
        <img
            src={product.imageUrl ? product.imageUrl : '/images/default_product@2x.jpg'}
            class="thumbnail__img"
            alt="thumbnail" />
        {#if product.id === lastProductAddedId}
            <div class="checkmark animate__animated animate__zoomIn">
                <div class="checkmark__icon">
                    <Lottie jsonPath="lottie/checkmark.json" loop={false} />
                </div>
            </div>
        {/if}
        <div
            class="thumbnail__overlay animate__animated animate__fadeIn"
            on:click={() => {
                handleClick(product);
            }}>
            <div class="animate__animated animate__pulse">
                <Button iconUrl="images/cart_icon_white@2x.svg" transparent>Add to Cart</Button>
            </div>
        </div>
    </div>
    <div class="details">
        {#if canSeeMoreInfo}
            <div class="info animate__animated animate__fadeIn">
                <div class="info__icon">
                    <Lottie jsonPath="lottie/info.json" loop={false} />
                </div>
                <div class="info-details">
                    <div class="info-details__desc">
                        {@html product.desc}
                    </div>
                </div>
            </div>
        {/if}

        <div class="details__group details__group--summary">
            <div class="details-summary">
                <div class="details-summary__title">{product.name}</div>
                <div class="details-summary__desc">{product.specs}</div>
            </div>
            <div class="details-retailer">
                <img src={getRetailerLogoUrl(product.retailerId)} height="47" alt="retailer" />
            </div>
        </div>
        <div class="details__group details__group--price">
            <div class="details-summary__price" class:details-summary__price--strike={product.salePrice}>
                {formatCurrency(product.price, $user.country.id, $user.country.currencyCode)}
            </div>
            {#if product.salePrice}
                <div class="details-summary__price details-summary__price--sale">
                    {formatCurrency(product.salePrice, $user.country.id, $user.country.currencyCode)}
                </div>
            {/if}
        </div>
    </div>
    <div class="actions">
        <button
            class="actions__btn-add"
            disabled={!product.inStock}
            on:click={() => {
                handleClick(product);
            }}>
            {product.inStock ? 'Add to Cart' : 'Out of Stock'}
        </button>
        <button class="actions__btn-info" on:mouseenter={handleMoreInfoShow} on:mouseleave={handleMoreInfoHide}>
            More Info
        </button>
    </div>
</div>
