import { writable } from 'stores/localStorage';
import db from 'services/db';
import { convertDocsToArray } from 'services/util';

const key = 'shop';
const initialValue = {
    retailers: [],
};

function createStore() {
    const store = writable(key, initialValue);

    return {
        subscribe: store.subscribe,

        fetch: async function (countryId) {
            const retailerDocs = await db
                .collection('retailers')
                .where('countryId', '==', countryId)
                .where('isActive', '==', true)
                .get();
            let retailers = convertDocsToArray(retailerDocs);

            store.set({ retailers });

            return { retailers };
        },
    };
}

export default createStore();
