import db from 'services/db';
import ORDERS_CONFIG from 'configs/orders';
import { convertDocsToArray, generateRetailerId, sanitizeObject } from 'services/util';

export async function getOrdersByState(stateId, countryId) {
    let ordersRef = db.collection('orders');

    ordersRef = ordersRef
        .where('state', '==', stateId)
        .where('countryId', '==', countryId)
        .orderBy('dateCreated', 'desc');

    if (stateId == ORDERS_CONFIG.states.cancelled.id) {
        ordersRef = ordersRef.limit(50);
    }

    if (stateId == ORDERS_CONFIG.states.delivered.id) {
        ordersRef = ordersRef.limit(100);
    }

    const docs = await ordersRef.get();
    return convertDocsToArray(docs);
}

export async function updateOrderState(order, newStateId) {
    let orderRef = db.collection('orders').doc(order.id);

    return orderRef.update({
        dateModified: firebase.firestore.FieldValue.serverTimestamp(),
        state: newStateId,
        'loan.id': order.loan.id || null,
    });
}

export async function updateOrderLoanId(order) {
    let orderRef = db.collection('orders').doc(order.id);

    return orderRef.update({
        dateModified: firebase.firestore.FieldValue.serverTimestamp(),
        'loan.id': order.loan.id || null,
    });
}

export async function updateOrder(order) {
    let orderRef = db.collection('orders').doc(order.id);

    return orderRef.update({
        dateModified: firebase.firestore.FieldValue.serverTimestamp(),
        dateScheduled: order.dateScheduledConfirmed ? order.dateScheduled : null,
        notes: order.notes || null,
        'loan.id': order.loan.id || null,
        'loan.creditCapacity': parseInt(order.loan.creditCapacity, 10) || null,
        'formatted.creditCapacity': order.formatted.creditCapacity || null,
        'formatted.dateScheduled': order.formatted.dateScheduled || null,
    });
}

export async function getRetailers(countryId, allowedRetailers = null) {
    let retailersRef = db.collection('retailers');

    retailersRef = retailersRef.where('countryId', '==', countryId);

    if (allowedRetailers) {
        retailersRef = retailersRef.where(firebase.firestore.FieldPath.documentId(), 'in', allowedRetailers);
    }

    const docs = await retailersRef.get();
    return convertDocsToArray(docs);
}

export async function getCountries(allowedCountries = null) {
    let countriesRef = db.collection('countries');

    if (allowedCountries) {
        countriesRef = countriesRef.where(firebase.firestore.FieldPath.documentId(), 'in', allowedCountries);
    }

    const docs = await countriesRef.get();

    return convertDocsToArray(docs);
}

export async function getProductsByRetailer(retailer) {
    let productsRef = db.collection('products');

    productsRef = productsRef.where('retailerId', '==', retailer).orderBy('dateCreated', 'desc');

    const docs = await productsRef.get();
    return convertDocsToArray(docs);
}

export async function updateProduct(product) {
    let productRef = db.collection('products').doc(product.id);

    let { name, specs, code, price, retailerId, retailerPrice, metadata, inStock, isFeatured, imageUrl } = product;

    return productRef.update({
        dateModified: firebase.firestore.FieldValue.serverTimestamp(),
        name,
        imageUrl,
        code,
        specs,
        desc: product.desc || null,
        price,
        inStock: inStock,
        isFeatured: isFeatured || false,
        'metadata.keywords': metadata.keywords,
        salePrice: product.salePrice || null,
        retailerId,
        retailerPrice,
    });
}

export async function addProduct(product) {
    let productRef = db.collection('products');

    return productRef.add({
        dateCreated: firebase.firestore.FieldValue.serverTimestamp(),
        ...product,
    });
}

export async function saveRetailer(retailer) {
    let retailerRef;

    if (!retailer.id) {
        let id = generateRetailerId(retailer.displayName, retailer.countryId);
        retailerRef = db.collection('retailers').doc(id);
        return retailerRef.set({
            dateCreated: firebase.firestore.FieldValue.serverTimestamp(),
            ...retailer,
        });
    } else {
        retailerRef = db.collection('retailers').doc(retailer.id);
        let { displayName, isActive, countryId, logoUrl } = retailer;

        retailerRef.update({
            dateModified: firebase.firestore.FieldValue.serverTimestamp(),
            displayName,
            isActive: isActive ? true : false,
            countryId,
            logoUrl: logoUrl ? logoUrl : null,
        });
    }
}

export async function removeProduct(productId) {
    let productRef = db.collection('products').doc(productId);
    return productRef.delete();
}

export default {
    getOrdersByState,
    updateOrderState,
    updateOrderLoanId,
    updateOrder,
    getRetailers,
    getCountries,
    getProductsByRetailer,
    addProduct,
    updateProduct,
    removeProduct,
    saveRetailer,
};
